/* ==========================================================================
  Main.js
========================================================================== */

(function($) {

  // Responsive videos
  $(document).ready(function(){
    $('body').fitVids();
  });

$(document).ready(function(){
  $(".sorted").removeClass("true");
  $('#sort_toggle').click(function() {
      $(".sorted").toggleClass("true");
    });
      });

})(jQuery);
